import React from 'react';
import { Layout, SectionContact } from '@websites/components';
import { YellowBusinessContext } from '../contexts/YellowBusinessProvider';
import { CartContext } from '../contexts/CartProvider';

function ContactPage() {
  const { yellowBusiness, sections } = React.useContext(YellowBusinessContext);
  const cart = React.useContext(CartContext);

  const hasTheme = !!yellowBusiness?.website?.theme;

  return (
    <>
      {hasTheme && (
        <Layout
          isEcommerce
          yellowBusiness={yellowBusiness}
          sections={sections}
          cart={cart}
          pageName={sections?.contactBusinessSection?.name}
        >
          <SectionContact section={sections?.contactBusinessSection} />
        </Layout>
      )}
    </>
  );
}

export default ContactPage;
